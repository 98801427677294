import React, { Component } from "react";
import Navnarjs from "../component/Navbar/Navnarjs";
import Silider from "../component/Silider/Silider";
import Contet from "../component/Contet/Contet";
import Uzunbar from "../component/Uzunbarr/Uzunbar";
import Contenttwo from "../component/Contet/Contenttwo";
import Footer from "../component/Footer/Footer";
export default class Home extends Component {
  render() {
    return (
      <div>
        <Navnarjs />
        <Silider />
        <Contet />
        <Uzunbar />
        <Contenttwo />
        <Footer />
        
      </div>
    );
  }
}
