import React, { Component } from "react";
import { MdLanguage } from 'react-icons/md';
import { BiChevronDown } from 'react-icons/bi';

import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
  Container,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Resim from "../../img/unnamed.png";
export default class Navnarjs extends Component {
  render() {
    return (
      <div>
        <Navbar bg="dark" color="dark" expand="md">
          <Container>
            <Navbar.Brand href="/">
              <img src={Resim} className="logo"></img>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
              <Nav className="me-auto"></Nav>

              <Nav>
                <Nav.Link>
                  <Link to="/" style={{ textDecoration: "none" }}>
                    <p className="navlink"><b>Home</b></p>
                  </Link>
                </Nav.Link>

                <Nav.Link>
                  <Link to="/about" style={{ textDecoration: "none" }}>
                    <p className="navlink"><b>About</b></p>
                  </Link>
                </Nav.Link>
                
                <Nav.Link href='https://blog.fiskoz.com/'><a style={{ textDecoration: 'none' }}><p className="navlink"><b>Blog</b></p></a></Nav.Link>
                <Nav.Link>
                  <Link to="/contact" style={{ textDecoration: "none" }}>
                    <p className="navlink"><b>Contact</b></p>
                  </Link>
                </Nav.Link>
                <Nav.Link>
                  <Link to="/tr" style={{ textDecoration: "none" }}>
                  <b className="dil"><MdLanguage />TR<BiChevronDown/></b>
                  </Link>
                </Nav.Link>
              </Nav>
              
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    );
  }
}
