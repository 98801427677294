import React, { Component } from 'react'
import Resimtwo from '../../img/blog-bg.jpg';

export default class Uzunbar extends Component {
  render() {
    return (
        <div className='uzunbar'>
        
   
      </div>
    )
  }
}
