import React, { Component } from "react";
import Resim from "../../img/danışmanlık.jpg";

export default class Contet extends Component {
  render() {
    return (
      <center>
        <div className="content">
          <div className="container-fluid">
            <div className="row toplam">
              <div className="col-lg-5 icerik">
                <img src={Resim} />
              </div>
              <div className="col-lg-7 icerikyazi">
                <h3>Investments And Consultancy</h3>
                <p>
                  As a small but fast growing investment company we manage
                  multimillion dollar assets, spanning 3 business segments and
                  multiple investment vehicles, investing wisely and responsibly
                  over the long-term. Every business process matters and your
                  organization can only reach its full potential with the right
                  partners, similar cultures and when all your processes are as
                  efficient and effective as they can be. Achieving process
                  excellence is essential. Every improved process is progress.
                  Our work is about the science of change, creation of growth,
                  maximizing profitability and the art of unleashing the
                  potential of people in an organization in order to execute
                  strategy in unprecedented ways.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </center>
    );
  }
}
