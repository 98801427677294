import React, { Component } from 'react';
import Gizlilik from '../component/Gizlilik/Gizliliken';
import Navnarjs from '../component/Navbar/Navnarjs';
import Footer from '../component/Footer/Footer';

class Gizlilikpolitikasii extends Component {
    render() {
        return (
            <div>
                <Navnarjs/>
                <Gizlilik/>
                <Footer/>
            </div>
        );
    }
}

export default Gizlilikpolitikasii;