import React, { Component } from "react";
import FooterTR from "../component/Footer/FooterTR";
import NavnarjsTR from "../component/Navbar/NavnarjsTR";
import Resim from "../../src/img/slider_loft2 (1).jpg"
import Resim2 from "../../src/img/istanbul-bosphorus-bridge-at-sunset-with-car-traff-2021-08-29-17-48-45-utc (1).jpg"

import "./abount.css"

export default class Hakkimizda extends Component {
  render() {
    return (
      <div className="aboutfon">
        <NavnarjsTR />

        <div className="about">
          <div class="container">
            <div class="row align-items-center my-5">
              <div class="col-lg-7">
                <img 
                  className="resimboy img-fluid rounded mb-4 mb-lg-0"
                  src={Resim}
                  alt=""
                />
              </div>
              <div class="col-lg-5">
                <h1 class="font-weight-light">Hakkımızda</h1>
                <p className="ortala1">
                  Operational across the globe since 1997 including local
                  representatives or offices in cities such as London,
                  Amsterdam, Seattle, Singapore, Jakarta and Dubai we also offer
                  a broad set of services (including but not limited to business
                  development, project management, legal consulting, lobbying)
                  to foreign investors in Turkey. We hold advanced expertise and
                  experience in Real Estate, Tourism, Technology and Wealth
                  Management.
                </p>
              </div>
            </div>

            <div class="row align-items-center my-5">
              <div class="col-lg-7">
                <h1 class="font-weight-light"></h1>
                <p className="ortala2">
                  Our experienced interdisciplinary team ensures market-leading
                  practices with a deep and practical understanding of market
                  terms and developments, regulatory compliance and solutions,
                  and the intersection of private credit, capital raising and
                  asset management that are critical to industry participants
                  and potential entrants. We’re a creative, design-led both
                  business consulting and investment firm that delivers growth
                  through contextual brand strategy, effective marketing and
                  predictable sales solutions for your business.
                </p>
              </div>
              <div class="col-lg-5">
                <img
                  class="img-fluid rounded mb-4 mb-lg-0"
                  src={Resim2}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>

        <FooterTR />
      </div>
    );
  }
}
