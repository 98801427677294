import React, { useRef, useState, Component } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";

// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";

// import required modules
import { Pagination, Navigation } from "swiper";

import "../../App.css";
// Import Swiper styles
import "swiper/swiper.min.css";

import SliderData from "../../index.json";

export default class SiliderTR extends Component {
  render() {
    SwiperCore.use([Autoplay, Pagination, Navigation]);
    return (
      <div>
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >
          {SliderData.map((item, index) => (
            <SwiperSlide>
              <table>
                <tr>
                  <td>
                    <tr>
                      <div
                        className="resim"
                        data-swiper-autoplay="1000"
                        key={item.key}
                      >
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            `/images/ing${index + 1}.webp`
                          }
                        />
                      </div>
                      <div className="sarilik"></div>
                    </tr>
                  </td>
                </tr>
              </table>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    );
  }
}
