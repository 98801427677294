import React, { Component } from "react";
import Resim from "../../img/slider_loft2 (1).jpg";

export default class ContenttwoTR extends Component {
  render() {
    return (
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-7 twoicerik">
              <h3>Türkiye'de İş Yapmak</h3>
              <p>
                İstanbul, Türkiye merkezliyiz ve Türkiye içinde veya dışında iş
                yapmakla ilgileniyorsanız, sizin için mükemmel bir ortak
                olabiliriz. Maliyetleri en aza indirmeye çalışıyorsanız, iş
                yapılarınızı tam, yarı zamanlı veya sözleşmeli düzeyde
                yönetmenize yardımcı olacak nitelikli avukatlar, varlık
                danışmanları ve profesyoneller sağlayabiliriz, böylece
                maliyetleri önemli ölçüde azaltırız. Size ve şirketinize
                danışabilir, onları temsil edebilir veya ortak olabiliriz.
                Sadece 2022 ve 2023'te İstanbul, Türkiye'deki gayrimenkul
                portföyümüz ve diğer segmentlerdeki yatırımlarımız 200 milyon
                doları aşıyor. Ağırlıklı olarak İstanbul'da faaliyet
                gösteriyoruz ancak ülkenin diğer bölgelerine kolayca
                genişleyebiliriz.{" "}
              </p>
            </div>
            <div className="col-lg-5 threeicerik">
              <img src={Resim} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
