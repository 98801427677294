import React, { Component } from "react";
import NavbarjsTR from "../component/Navbar/NavnarjsTR";
import SiliderTR from "../component/Silider/SiliderTR";
import ContetTR from "../component/Contet/ContetTR";
import Uzunbar from "../component/Uzunbarr/Uzunbar";
import ContenttwoTR from "../component/Contet/ContenttwoTR";
import FooterTR from "../component/Footer/FooterTR";
export default class Home extends Component {
  render() {
    return (
      <div>
        <NavbarjsTR />
        <SiliderTR />
        <ContetTR />
        <Uzunbar />
        <ContenttwoTR />
        <FooterTR />
        
      </div>
    );
  }
}
