import React, { Component } from "react";
import { Link } from "react-router-dom";
export default class FooterTR extends Component {
  render() {
    return (
      <div className="footer">
        <div className="container-fluid">
        <p className="gizlilikfooter"> <Link to="/Gizlilikpolitakasi" style={{ textDecoration: "none" }}>
                    <p className=""><b>Gizlilik Politikası</b></p>
                  </Link></p>
          <p className="tumhaklar">Tüm hakları saklıdır ©️ 2022 Fiskoz.com</p>
         
        </div>
      </div>
    );
  }
}
