
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './Pages/Home'
import HomeTR from './Pages/HomeTR'
import Abount from './Pages/Abount';
import React from 'react';
import Contact from './Pages/Contact';
import Hakkimizda from './Pages/Hakkimizda';
import Iletisim from './Pages/Iletisim';
import Gizlilikpolitikasi from './Pages/Gizlilikpolitikasi';
import Privacypolicyolicy from './Pages/Privacypolicy';


function App() {
  return (
    <div className="App">
     <Router>
      <Routes>
      <Route path="/" element={<Home/>}/>
      <Route path="/tr" element={<HomeTR/>}/>
      <Route path="/about" element={<Abount/>}/>
      <Route path="/hakkimizda" element={<Hakkimizda/>}/>
      <Route path="/contact" element={<Contact/>}/>
      <Route path="/iletisim" element={<Iletisim/>}/>
      <Route path="/Gizlilikpolitakasi" element={<Gizlilikpolitikasi/>}/>
      <Route path="/privacypolicy" element={<Privacypolicyolicy/>}/>
      </Routes>
      </Router>
    </div>
  );
}

export default App;
