import React, { Component, useRef, useState } from "react";
import FooterTR from "../component/Footer/FooterTR";
import NavnarjsTR from "../component/Navbar/NavnarjsTR";
//import emailjs from "emailjs-com";
import emailjs from "@emailjs/browser";
import "./contact.css";

export default function Iletisim() {
  const [nedeni, setSelects] = useState();

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_pb3c1zz",
        "template_9wgnhvr",
        form.current,
        "n_Nb-bdOn1EkwOzlP"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Mesajınız bize ulaşmıştır.");
        },
        (error) => {
          console.log(error.text);
          alert("Bir sorun oluştu, lütfen tekrar deneyiniz.");
        }
      );
    e.target.reset();
  };

  return (
    <>
      <NavnarjsTR />
      <div>
        <div className="container">
          <form ref={form} onSubmit={sendEmail}>
            <div className="row pt-5 mx auto">
              <div className="test col-lg-6 col-md-12 col-sm-12 form-group">
                <h1>Bize Ulaşın</h1>
              </div>
            </div>

            <div className="blok row pt-5 mx auto">
              <div className="col-lg-6 col-md-12 col-sm-12 form-group"></div>

              <hr className="seper"></hr>

              <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                <h2>Hakkınızda</h2>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                <label className="ustyazi">
                  <b style={{ color: "red" }}>*</b> Adınız:
                </label>
                <p></p>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Adınızı giriniz"
                  name="name"
                  required
                />

                <label className="ustyazi">
                  <b style={{ color: "red" }}>*</b> Soyadınız:
                </label>
                <p></p>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Soyadınızı giriniz"
                  name="Soyadınız"
                  required
                />

                <label className="ustyazi">
                  <b style={{ color: "red" }}>*</b> Email Adresi:
                </label>
                <p></p>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email adresinizi giriniz"
                  name="email"
                />

                <label className="ustyazi">Telefon Numaranız:</label>
                <p></p>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Telefon numaranızı giriniz"
                  name="telephone"
                />
              </div>
              <hr className="seper"></hr>

              <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                <h2>Şirketiniz hakkında</h2>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                <label className="ustyazi">
                  <b style={{ color: "red" }}>*</b> Şirket Adınız:
                </label>
                <p></p>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Şirket adınızı giriniz"
                  name="companyname"
                  required
                />
              </div>
              <hr className="seper"></hr>

              <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                <h2>Mesajınız</h2>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                <label className="ustyazi">
                  <b style={{ color: "red" }}>*</b> Mesaj:
                </label>
                <p></p>
                <textarea
                  className="form-control"
                  placeholder="Fiskoz ile iletişime geçme sebebinizi 1,500 karakter ya da daha az olacak şekilde tanımlayınız..."
                  name="message"
                  id=""
                  cols="30"
                  rows="10"
                ></textarea>
              </div>

              <div className="col-lg-6 col-md-12 col-sm-12 form-group"></div>
              <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                <input
                  className="contactbutton"
                  type="submit"
                  value="Mesajı Gönder"
                  name="Send"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <FooterTR />
    </>
  );
}
