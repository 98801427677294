import React, { Component, useRef, useState } from "react";
import Footer from "../component/Footer/Footer";
import Navnarjs from "../component/Navbar/Navnarjs";
//import emailjs from "emailjs-com";
import emailjs from "@emailjs/browser";
import "./contact.css";

export default function Contact() {
  const [nedeni, setSelects] = useState();

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_pb3c1zz",
        "template_9wgnhvr",
        form.current,
        "n_Nb-bdOn1EkwOzlP"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Your message has reached us.");
        },
        (error) => {
          console.log(error.text);
          alert("Something went is wrong.");
        }
      );
    e.target.reset();
  };

  return (
    <>
      <Navnarjs />
      <div>
        <div className="container">
          <form ref={form} onSubmit={sendEmail}>
            <div className="row pt-5 mx auto">
              <div className="test col-lg-6 col-md-12 col-sm-12 form-group">
                <h1>Contact Us</h1>
              </div>
            </div>

            <div className="blok row pt-5 mx auto">
              <div className="col-lg-6 col-md-12 col-sm-12 form-group"></div>

              <hr className="seper"></hr>

              <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                <h2>About You</h2>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                <label className="ustyazi">
                  <b style={{ color: "red" }}>*</b> First Name:
                </label>
                <p></p>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Your name"
                  name="name"
                  required
                />

                <label className="ustyazi">
                  <b style={{ color: "red" }}>*</b> Last Name:
                </label>
                <p></p>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Your surname"
                  name="surname"
                  required
                />

                <label className="ustyazi">
                  <b style={{ color: "red" }}>*</b> Email Address:
                </label>
                <p></p>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Your email address"
                  name="email"
                />

                <label className="ustyazi">Phone Number:</label>
                <p></p>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Your phone number"
                  name="telephone"
                />
              </div>
              <hr className="seper"></hr>

              <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                <h2>About your business</h2>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                <label className="ustyazi">
                  <b style={{ color: "red" }}>*</b> Company Name:
                </label>
                <p></p>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Your company name"
                  name="companyname"
                  required
                />
              </div>
              <hr className="seper"></hr>

              <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                <h2>Your message</h2>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                <label className="ustyazi">
                  <b style={{ color: "red" }}>*</b> Message:
                </label>
                <p></p>
                <textarea
                  className="form-control"
                  placeholder="Describe your reason for contacting Fiskoz in 1,500 characters or less..."
                  name="message"
                  id=""
                  cols="30"
                  rows="10"
                ></textarea>
              </div>

              <div className="col-lg-6 col-md-12 col-sm-12 form-group"></div>
              <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                <input
                  className="contactbutton"
                  type="submit"
                  value="Send Message"
                  name="Send"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
}
