import React, { Component } from 'react';

class Gizliliken extends Component {
    render() {
        return (
            <div className='gizlilik'>
                <center><h3>**Privacy Policy for Fiskoz ltd.**</h3>
                    <ul>
                        <li>*Effective Date: September 21, 2023</li>
                        <li>**1. Introduction**</li>
                        <li>Welcome to Fiskoz ltd.. We are a company specialized in real estate investments, technology and international trading.
                            This Privacy Policy is designed to help you understand how we collect, use, disclose, and safeguard your personal information.</li>
                        <li>**2. Information We Collect**</li>
                        <li>*2.1. Personal Information:* This includes your name, email address, phone number, and any other information you provide when you contact us, register on our website, or make a purchase.</li>
                        <li>*2.2. Technical Data:* We may collect information about the device you use to access our website, your IP address, browser type, and other related technical data.</li>
                        <li>*2.3. Usage Data:* This includes information about how you use our website, products, and services.</li>
                        <li>**3. How We Use Your Information**</li>
                        <li>*3.1. To Provide Our Services:* We use your information to process transactions, provide customer support, and ensure you receive the best user experience</li>
                        <li>*3.2. For Marketing:* With your consent, we may send you promotional materials about our products and services.</li>
                        <li>*3.3. For Research and Development:* We use collected data to improve our AI technologies, smart building solutions, and e-commerce platforms.</li>
                        <li>**4. Disclosure of Your Information**
                            We do not sell or rent your personal information to third parties. However, we may disclose your information:</li>
                        <li>*4.1. To comply with legal obligations.*</li>
                        <li>*4.2. To protect our rights and the rights of our users.*</li>
                        <li>*4.3. To third-party service providers that assist in our operations.*</li>
                        <li>**5. Data Security**
                            We implement a variety of security measures to protect your personal information. However, no method of transmission or storage is 100% secure. We cannot guarantee absolute security</li>
                        <li>**6. Third-Party Websites**
                            Our website may contain links to third-party websites. We are not responsible for the privacy practices of these external sites.</li>
                        <li>**7. Children’s Privacy**
                            Our services are not intended for individuals under the age of 18. We do not knowingly collect personal information from children.</li>
                        <li>**8. Your Rights**
                            You have the right to access, correct, or delete your personal information. You can also object to our processing of your personal data.</li>
                        <li>**9. Changes to This Privacy Policy**
                            We may update our Privacy Policy from time to time. We will notify you of any changes posting the new Privacy Policy on this page.</li>
                        <li>**10. Contact Us**
                            For any questions or concerns regarding this Privacy Policy, please contact us at:</li>
                        <li>*Email: info@fiskoz.com</li>
                        <li>*Address:</li>
                        <li>Fiskoz ltd.
                            Yenibosna Merkez Mah. Kavak Sk. No: 25 – 27A İç kapı no: 98
                            Bahçelievler – İstanbul</li>

                    </ul>
                </center>
            </div>
        );
    }
}

export default Gizliliken;