import React, { Component } from 'react';

class Gizlilikk extends Component {
    render() {
        return (
            <div className='gizlilik'>
              <center><h3>**Fiskoz Gizlilik Politikası**</h3>
             <ul>
                    <li>**1. Giriş**</li>
                    <li>Fiskoz ltd.’e hoş geldiniz. Biz, gayrimenkul geliştirme ve satışında uzmanlaşmış bir şirketiz, aynı zamanda AI, akıllı binalar ve e-ticarette teknoloji geliştirmede de faaliyet göstermekteyiz. Bu Gizlilik Politikası, kişisel bilgilerinizi nasıl topladığımızı, kullandığımızı, açıkladığımızı ve koruduğumuzu anlamanıza yardımcı olmak için tasarlanmıştır.</li>
                    <li>**2. Topladığımız Bilgiler**</li>
                    <li>2.1. Kişisel Bilgiler:* Bizimle iletişime geçtiğinizde, web sitemize kaydolduğunuzda veya bir satın alma işlemi yaptığınızda sağladığınız adınız, e-posta adresiniz, telefon numaranız ve diğer bilgiler.</li>
                    <li>2.2. Teknik Veriler:* Web sitemize erişim için kullandığınız cihaz hakkında, IP adresiniz, tarayıcı türü ve diğer ilgili teknik verileri toplayabiliriz.</li>
                    

                  <li> *2.3. Kullanım Verileri:* Web sitemizi, ürünlerimizi ve hizmetlerimizi nasıl kullandığınıza dair bilgiler.</li> 
<li>3. Bilgilerinizi Nasıl Kullanıyoruz**</li>
<li>*3.1. Hizmetlerimizi Sağlamak İçin:* İşlemlerinizi gerçekleştirmek, müşteri desteği sağlamak ve en iyi kullanıcı deneyimini almanızı sağlamak için bilgilerinizi kullanıyoruz.</li>
<li>*3.2. Pazarlama İçin:* Onayınızla, ürünlerimiz ve hizmetlerimiz hakkında tanıtım materyalleri gönderebiliriz.</li>
<li>*3.3. Araştırma ve Geliştirme İçin:* Toplanan verileri, AI teknolojilerimizi, akıllı bina çözümlerimizi ve e-ticaret platformlarımızı geliştirmek için kullanıyoruz.</li>
<li>**4. Bilgilerinizin Açıklanması**
Kişisel bilgilerinizi üçüncü taraflara satmıyor veya kiralamıyoruz. Ancak bilgilerinizi şu durumlarda açıklayabiliriz:</li>
<li>*4.1. Yasal yükümlülüklere uymak için.*</li>
<li>*4.2. Haklarımızı ve kullanıcılarımızın haklarını korumak için.*</li>
<li>*4.3. Operasyonlarımıza yardımcı olan üçüncü taraf hizmet sağlayıcılara.*</li>
<li>**5. Veri Güvenliği**
Kişisel bilgilerinizi korumak için çeşitli güvenlik önlemleri uyguluyoruz. Ancak, hiçbir iletim veya depolama yöntemi %100 güvenli değildir. Mutlak güvenliği garanti edemeyiz.</li>
<li>**6. Üçüncü Taraf Web Siteleri**
Web sitemiz, üçüncü taraf web sitelerine bağlantılar içerebilir. Bu dış sitelerin gizlilik uygulamalarından sorumlu değiliz.</li>
<li>**7. Çocukların Gizliliği**
Hizmetlerimiz 18 yaşın altındaki bireyler için tasarlanmamıştır. Bilinçli olarak çocuklardan kişisel bilgi toplamıyoruz.</li>
<li>**8. Haklarınız**
Kişisel bilgilerinize erişme, düzeltme veya silme hakkınız vardır. Ayrıca kişisel verilerinizin işlenmesine itiraz edebilirsiniz.</li>
<li>**9. Bu Gizlilik Politikasındaki Değişiklikler**
Gizlilik Politikamızı zaman zaman güncelleyebiliriz. Yapılan değişiklikleri bu sayfada yayınlayarak sizi bilgilendireceğiz.</li>
<li>**10. Bize Ulaşın**
Bu Gizlilik Politikası hakkında herhangi bir soru veya endişeniz için lütfen bize ulaşın:</li>
<li>*E-posta: info@fiskoz.com </li>
<li>Adres:</li>
<li>Fiskoz ltd.
Yenibosna Merkez Mah. Kavak Sk. No: 25 – 27A İç kapı no: 98
Bahçelievler – İstanbul</li>



                </ul>
                </center>  
            </div>
        );
    }
}

export default Gizlilikk;