import React, { Component } from "react";
import Resim from "../../img/slider_loft2 (1).jpg";

export default class Contenttwo extends Component {
  render() {
    return (
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-7 twoicerik">
              <h3>Doing Business In Turkey</h3>
              <p>
                We’re based in Istanbul, Turkey and if you’re interested in
                doing with business in or out of Turkey we might be the perfect
                partner for you. If you seek to minimize costs, we can provide
                you qualified lawyers, wealth advisers, and professionals to
                help you manage your business structures either on a full,
                part-time, or contracted level, thus significantly decreasing
                costs. We can consult, represent or partner up with you and your
                company. In 2022 and 2023 alone our real-estate portfolio and
                investments in other segments in Istanbul, Turkey exceed $200
                million. We are mainly operational in Istanbul but can easily
                expand to other parts of the country.{" "}
              </p>
            </div>
            <div className="col-lg-5 threeicerik">
              <img src={Resim} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
