import React, { Component } from "react";
import Resim from "../../img/danışmanlık.jpg";

export default class ContetTR extends Component {
  render() {
    return (
      <center>
        <div className="content">
          <div className="container-fluid">
            <div className="row toplam">
              <div className="col-lg-5 icerik">
                <img src={Resim} />
              </div>
              <div className="col-lg-7 icerikyazi">
                <h3>Yatırımlar ve Danışmanlık</h3>
                <p>
                  Küçük ama hızlı büyüyen bir yatırım şirketi olarak, 3 iş
                  segmentini ve birden fazla yatırım aracını kapsayan, uzun
                  vadede akıllıca ve sorumlu bir şekilde yatırım yapan
                  milyonlarca dolarlık varlıkları yönetiyoruz. Her iş süreci
                  önemlidir ve kuruluşunuz ancak doğru ortaklarla, benzer
                  kültürlerle ve tüm süreçleriniz olabildiğince verimli ve
                  etkili olduğunda tam potansiyeline ulaşabilir. Süreç
                  mükemmelliğine ulaşmak esastır. İyileştirilmiş her süreç
                  ilerlemedir. Çalışmamız değişim bilimi, büyüme yaratma,
                  karlılığı en üst düzeye çıkarma ve stratejiyi benzeri
                  görülmemiş şekillerde yürütmek için bir organizasyondaki
                  insanların potansiyelini açığa çıkarma sanatı ile ilgilidir.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </center>
    );
  }
}
