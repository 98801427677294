import React, { Component } from 'react';
import Gizlilik from '../component/Gizlilik/Gizlilikk';
import NavnarjsTR from '../component/Navbar/NavnarjsTR';
import FooterTR from '../component/Footer/FooterTR';

class Gizlilikpolitikasi extends Component {
    render() {
        return (
            <div>
                <NavnarjsTR/>
                <Gizlilik/>
                <FooterTR/>
            </div>
        );
    }
}

export default Gizlilikpolitikasi;